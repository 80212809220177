import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidator {
  /* Compares one field to another */
  static matchValidator(otherControl: AbstractControl): ValidatorFn {
    return ({ value }: AbstractControl): ValidationErrors | null => {
      if (value !== otherControl.value) {
        return { match: true };
      }

      return null;
    };
  }
}
